import { useState } from "react"

function Cookies() {
    const [isOpen, setIsOpen] = useState("");

    return (
        <>
            <div className={isOpen} style={{
                backgroundColor: "#23272f",
                padding: "2rem",
                fontSize: "65%",
                textAlign: "justify",
            }}>
                <p>Questo sito non trattiene ne memorizza nessun tipo di cookie. Nè cookies tecnici nè cookies di profilazione in quanto la struttura del medesimo
                    non ne prevede l'utilizzo. Per cui nessun dato verrà memorizzato in alcun modo in nessuna memoria ne fisica ne virtuale.
                </p>
                <button onClick={() => setIsOpen("none")} style={{
                    position: "inherit",
                    fontSize: "80%",
                    padding: "0.5rem"
                }}>Ho capito</button>
            </div>    
        </>    
    )
}

export default Cookies