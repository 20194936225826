import Aside from './Aside';
import Nav from './Nav';
import Header from './Header';
import Footer from './Footer';
import Whatsapp from './Whatsapp';
import Cookies from './Cookies';


export default function Layout({children}) {
    return (
        <>
        <Cookies/>
        <Nav/>
        <Header/>
        <Aside/>
        {children}
        <Footer/>
        <Whatsapp/>
    </> 

    )
}