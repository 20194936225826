import webPage from "../../data/Webpage";
import "./Home.css"


export default function Home() {
    const webList = webPage.map(web => {
        return (
            <article style={{
                color: "#47b5dc",
                backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${web.background})`,
                backgroundSize: "cover",
                backgroundPosition: "center"
            }}>
                <h3 className="title">{web.title}</h3>
                <img src={web.img} className="logo" alt={web.title}/>
                <button><a href={web.link} target="_blank">Scopri di più</a></button>
            </article>
        )
    })

    return (
        <>
            <section className="container">
                {webList}
            </section>
        </>
    )
}