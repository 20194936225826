export default function ContentCreator() {
    return (
        <div style={{
            padding: "1rem",
            textAlign: "justify"
        }}>
            <h2>Content Creator</h2>
            <p>
                Chi è un content creator? Come suggerisce la parola stessa è un creatore di contenuti: scritti, video, immagini... 
                insomma, è qualcuno che ti toglie l'incombenza di dover creare contenuti web per la tua attività.
                Oppure è qualcuno che crea qualcosa per consigliare un tuo prodotto ho servizio.
            </p>
            <p>Hai bisogno di un video per pubblicizzare un tuo prodotto o servizio? 
                Hai bisogno di qualcuno che aggiorni il tuo sito web regolarmente? Sei nel posto giusto.
                Grazie alla mia esperienza poliedrica maturata nel tempo offro servizi di ghostwriting, copyrighting oppure posso 
                creare per te un video da pubblicare nel canale YouTube de l'uomo di casa. 
                Quasiasi sia la tua necessità contattami e ti fornirò soluzioni adatte a te e alla 
                tua attività. 
            </p>
            <p>Ora ti starai chiededo? Ma quanto mi costerà tutto ciò? Posso tranquillizzarti, io ho la necessità di fornire sempre 
                stimoli, prodotti, servizi nuovi ai miei follower, <spam style={{
                    color: "#47b5dc",
                }} > quindi se il tuo prodotto o servizio sarà di mio interesse tutto il 
                mio lavoro non ti costerà nulla</spam>... si si, hai capito bene, non ti costerà nulla.
            </p>
            <p>
                Qui sotto ti sottopongo alcuni esempi del mio lavoro come video maker, per qualsiasi domanda, considerami a tua 
                disposizione. Scrivimi direttamente su WhatsApp e ti risponderò al volo.
            </p>
            <div style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "1rem",
                justifyContent: "center"
            }}>
            <iframe width="350" height="197" src="https://www.youtube.com/embed/zAHbcK-fuNM?si=jfvMs5o4ljubmA_T" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            <iframe width="350" height="197" src="https://www.youtube.com/embed/c__DlwdtBlU?si=xMK6QBw1nJ0fNRrk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            <iframe width="350" height="197" src="https://www.youtube.com/embed/ikFxwtHeHSM?si=pj77yDcm92iB5ydN" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
            <p>Ecco alcuni brand con cui collaboro...</p>
            <div style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "1rem",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d4/Leroy_Merlin.svg/200px-Leroy_Merlin.svg.png"/>
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSvS4GGMdZBK1K8wumDEvrXH9lyVg5KNardd7UOBnfPQw&s"/>
                <img src="https://cigman.com/cdn/shop/files/1_5323e73f-a73a-4f49-872f-ea57dd17edbc.png?v=1706585256&width=240"/>
            </div>
        </div>
    )
}