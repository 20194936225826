import './Aside.css'
import Home from './Pages/Home';

export default function Aside() {
    function openCloseParagraph() {
        let pFade = document.querySelector('#pFade');
        let spanMore = document.querySelector('#readMoreAboutMe')
        if(pFade.classList.contains('none')) {
            pFade.classList.remove('none');
            spanMore.classList.add('none');
        } else {
            pFade.classList.add('none');
            spanMore.classList.remove('none');

        }
    }

    return (
        <>
            <aside className="aside">
              <div className='aside1'>
                <h2>About me</h2>
                <p>  
                    Il mio motto è <span className='boldtext'>"SE NON SO QUALCOSA... LO IMPARO"</span> questo è quello che mi sono sempre posto come obiettivo. Penso che nessuna meta sia irraggiungibile se con costanza ed umiltà ci si informa e si è disposti ad imparare e ad accettare i consigli. <span id='readMoreAboutMe' className='boldtext pointer' onClick={() => openCloseParagraph()}>read more...</span>
                </p>  
                <p id='pFade' className="none">  
                    Per questo nonostante i miei studi dicessero che ero un ragioniere, un perito commerciale e poi anche un Analista Contabile non mi sono fermato lì; ho colto un opportunità per imparare un altro mestiere: il sistemista.<br/><br/>
                    Con l’avvento della crisi del 2013 ci sono stati dei periodi bui che hanno portato alla nascita del progetto “L’uomo di casa”, il mio canale YouTube e successivamente il sito internet uomodicasa.it dove consiglio cose che io stesso ho imparato e dove continuo ad imparare grazie ai miei followers.<br/><br/>
                    Grazie all’esperienza maturata come Content Creator e nel Web Marketing  mi sto aprendo a nuovi orizzonti lavorativi… non li conosco? Non c’è problema… <span className="boldtext">IMPARO</span>. <span className='boldtext pointer' onClick={() => openCloseParagraph()}>read less</span>
                </p>
              </div>
              <div className='aside2'>
              <h2>My Skill</h2>
              <div>
                  <ul>
                    <li>
                        <span>HTML</span><meter id="html" min="0" max="100" value="90"></meter>
                    </li>
                    <li>
                        <span>CSS</span><meter id="css" min="0" max="100" value="80"></meter>
                    </li>
                    <li>
                        <span>JS</span><meter id="js" min="0" max="100" value="60"></meter>
                    </li>
                    <li>
                        <span>React</span><meter id="js" min="0" max="100" value="50"></meter>
                    </li>
                    <li>
                      <span>Wordpress</span><meter id="html" min="0" max="100" value="90"></meter>
                  </li>
                    <li>
                        <span>Photo Editing</span><meter id="pe" min="0" max="100" value="60"></meter>
                    </li>
                    <li>
                        <span>Video Editing</span><meter id="ve" min="0" max="100" value="80"></meter>
                    </li>
                  </ul>
                </div>
                </div>    
            </aside>
        </>
    )
}