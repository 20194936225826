import { NavLink } from "react-router-dom";


export default function Nav() {
     function openCloseMenu() {
        let divModale = document.querySelector('#divModale');
        if(divModale.classList.contains('none')) {
            divModale.classList.remove('none')
        } else {
            divModale.classList.add('none')
        }
     }

     function handleLink() {
        let divModale = document.querySelector('#divModale');
        divModale.classList.add('none');
     }

    return (
        <>
            <div className="nav">
                <h3>Daniele Chiuri</h3>
                <div>
                    <svg className='svg' id='btnMenu' onClick={() => openCloseMenu()} xmlns="http://www.w3.org/2000/svg" 
                    width='1rem' viewBox="0 0 448 512">
                        <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"/></svg>  
                </div>
                <div className="bigMenu">
                    <span className="spanNav"><NavLink to='/' style={({isActive}) => {
                        return {
                            listStyleType: "none",
                            color: isActive ? "#47b5dc" : "white",
                            textDecoration:"none",
                            fontWeight: isActive ? "bold" : "",
                            padding: "1rem",
                           
                        }
                    }}>Home</NavLink></span>
                    <span className="spanNav"><NavLink to='services' style={({isActive}) => {
                        return {
                            listStyleType: "none",
                            color: isActive ? "#47b5dc" : "white",
                            textDecoration:"none",
                            fontWeight: isActive ? "bold" : "",
                            padding: "1rem",
                           
                        }
                    }}>Servizi</NavLink></span>
                    <span className="spanNav"><NavLink to='about' style={({isActive}) => {
                        return {
                            listStyleType: "none",
                            color: isActive ? "#47b5dc" : "white",
                            textDecoration:"none",
                            fontWeight: isActive ? "bold" : "",
                            padding: "1rem",
                           
                        }
                    }}>About Me</NavLink></span>
                </div>
                <div className="inputSearch"><input type="search" placeholder="Cerca"/></div>
            </div>
            <div id='divModale' className='modale none'>
                <ul className="ulModale">
                    <li onClick={() => handleLink()}><NavLink to='/' style={({isActive}) => {
                        return {
                            listStyleType: "none",
                            color: isActive ? "#47b5dc" : "white",
                            textDecoration:"none",
                            fontWeight: isActive ? "bold" : "",
                            display: "flex" , 
                            padding: "1rem",
                           
                        }
                    }}>Home</NavLink></li>
                    <li onClick={() => handleLink()}><NavLink to='services' style={({isActive}) => {
                        return {
                            listStyleType: "none",
                            color: isActive ? "#47b5dc" : "white",
                            textDecoration:"none",
                            fontWeight: isActive ? "bold" : "",
                            textAlign:"center", 
                            padding: "1rem",
                        }
                    }}>Servizi</NavLink></li>
                    <li onClick={() => handleLink()}><NavLink to='about' style={({isActive}) => {
                        return {
                            listStyleType: "none",
                            color: isActive ? "#47b5dc" : "white",
                            textDecoration:"none",
                            fontWeight: isActive ? "bold" : "",
                            textAlign:"center", 
                            padding: "1rem", 
                        }
                    }}>About Me</NavLink></li>
                </ul>
            </div>
        </>
    )
}