export default function SysAdmin() {
    return (
        <div style={{
            backgroundImage: `linear-gradient(rgba(0,0,0,0.8), rgba(0,0,0,0.7)), url("https://www.danielechiuri.it/img/cloud.png")`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            padding: "1rem",
            borderRadius: "1rem",
            margin: "1rem",
            textAlign: "justify"
        }}>
        <h2>Amministrazione di Sistema</h2>
        <p>
            Ormai la maggioranza delle imprese ha una serie di computer collegati in rete, un server,
            alcune stampanti, forse un NAS ecc... tantissimi dispositivi che vanno manutenuti e messi in 
            comunicazione l'uno con l'altro.
        </p>
        <p>
            Le aziende di una certa entità, che magari hanno centinaia di dispositivi di rete, possono permettersi
            di tenere un sistemista in loco. La maggioranza delle imprese invece non può avere un professionista
            come dipendente... semplicemente non se lo può permettere, anche perchè più la rete è piccola più il 
            lavoro diminuisce.
        </p>
        <p>
            Avete bisogno di una "sistemata" alla vostra rete? Volete sapere se potete ottimizzare la vostra rete
            informatica risparmiando tempo e denaro? Facciamoci una chiaccherata per scoprirlo.
        </p>
        </div>
    )
}