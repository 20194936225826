import './WebDeveloper.css';
import ButtonMailto from '../ButtonMailto';

export default function WebDeveloper() {
    return (
        <>
        <div className='pricingContainer'>        
            <div className='divPricing'>
                <h3 className='h3Pricing'>React</h3>
                <p  className='pPricing'><span  className='price'>€89</span><span  className='perPage'>/page</span></p>
                <span  className='order'>
                    <ButtonMailto label="Contattami per info" mailto="mailto:ads@thebranch.it" />
                </span>
                <h4 className='h4Pricing'>Lati positivi</h4>
                <ul  className='ulPricing'>
                    <li className='liPricing'>Economico</li>
                    <li className='liPricing'>Veloce</li>
                    <li className='liPricing'>Niente cookie banner</li>
                    <li className='liPricing'>Costi annuali bassissimi</li>
                </ul>
                <h4 className='h4pricing'>Lati Negativi</h4>
                <ul>
                    <li className='liPricing'>Aggiornamento complesso</li>
                    <li className='liPricing'>Non adatto per blog o ecommerce</li>
                    <li className='liPricing'>Niente dati analitici di traffico</li>
                </ul>
            </div>
                <div className='divPricing'>
                    <h3 className='h3Pricing'>Wordpress</h3>
                    <p  className='pPricing'><span  className='perPage'>from </span>
                    <span  className='price'>€899</span>
                    </p>
                    <span  className='order'>
                        <ButtonMailto label="Contattami per info" mailto="mailto:ads@thebranch.it" />
                    </span>
                    <h4 className='h4Pricing'>Lati positivi</h4>
                    <ul  className='ulPricing'>
                        <li className='liPricing'>Aggiornamento semplice</li>
                        <li className='liPricing'>Adatto per blog, ecommerce ecc...</li>
                        <li className='liPricing'>Dati analitici di traffico</li>
                    </ul>
                    <h4 className='h4pricing'>Lati Negativi</h4>
                    <ul>
                        <li className='liPricing'>Dimensioni elevate</li>
                        <li className='liPricing'>Caricamento più lento</li>
                        <li className='liPricing'>Costi annuali legati al GDPR della Privacy</li>
                    </ul>
                </div>
        </div>
        <br/><br/>
        </>
    )
}