import "./Wam.css";

export default function Wam() {
    return (
<>
<div style={{
    backgroundImage: `linear-gradient(rgba(0,0,0,0.8), rgba(0,0,0,0.7)), url("https://www.danielechiuri.it/img/app.jpg")`,
    padding: "1rem",
    borderRadius: "1rem",
    margin: "1rem"
    
}}>
            <h2>Web Account Manager</h2>
        <div style={{
            display: "flex",
            gap: "1rem",
            flexWrap: "wrap",
            justifyContent: "center",
            
        }}>
            <div className="vendite">
                <h4>Vendite Online</h4>
                <ul>
                    <li>Amazon</li>
                    <li>Mano Mano</li>
                    <li>Ebay</li>
                    <li>Subito</li>
                    <li>Etzy</li>
                    <li>ecc...</li>
                </ul>
            </div>
            <div className="vendite">
                <h4>Social</h4>
                <ul>
                    <li>Instagram</li>
                    <li>YouTube</li>
                    <li>Tik Tok</li>
                    <li>ecc..</li>
                </ul>
            </div>    
                <div className="vendite">
                <h4>Strumenti online</h4>
                <ul>
                    <li>PayPal</li>
                    <li>Acquisti online</li>
                    <li>Domini</li>
                    <li>Mail</li>
                    <li>ecc...</li>
                </ul>
            </div>
        </div>
        <p style={{
            padding: "1rem",
            textAlign: "justify"
        }}>Gli account citati sopra non sono esaustivi, in realtà ogni azienda ha i propri account per comprare e vendere,
            mail, domini e molto altro. Come riuscire a tenere traccia di tutto questo traffico online? Come evitare di perdere
            password, o come evitare di avere degli account social ma di non utilizzarli al meglio? 
            Possiamo risolvere tutti questi problemi insieme. Contattatemi per una chiacchierata senza impegno. 
        </p>
</div>
</>


    )
}