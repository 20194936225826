import "./About.css"

let me = "https://www.danielechiuri.it/img/me.jpg";

export default function About() {
    return (
        <>
        <div className="containerAbout">
            <div className="imgContainer">
                <img id="daniele" alt="daniele" src={me}/>
            </div>
            <div className="aboutMeParagraph">
                <p>
                    <span className="active">Web Developer, Sys Admin, Web Account Manager</span> e molto altro... tantissimi lavori, tantissima esperienza ma nel 
                    nel contempo sinonimo di una vita... movimentata, almeno in campo lavorativo.
                    Di studio: ragioniere ma dopo 10 anni di contabilità fornitori e analisi banche mi stanco e mi trasferisco insieme a mia moglie 
                    Marzia in un'altra città. Dopo qualche lavoretto come sistemista nella nuova città non riesco a stabilizzarmi e torno 
                    all'ovile... Torino. 
                </p>    
                <p>    
                    In un periodo molto buio della mia vita nasce, grazie al mio amico<span className="active"> cuoredicioccolato.it</span> il progetto "l'uomo di casa":
                    un canale YouTube con cui volevo condividere con gli altri l'arte di arrangiarsi da soli... purtroppo in quel periodo
                    i soldi erano pochi e non potevo spendere per idraulici, elettricisti, falegnami... quindi ho iniziato
                    a costruirmi tutto da solo.
                </p>
                <p>
                    Dopo più di un anno facendo ogni tipo di lavoro che trovavo: pulizie, facchinaggio, cameriere ecc... finalmente trovo 
                    stabilità a Modena dove inizio il mio percorso come Wordpress Web Developer e Web Account Manager. Così mi metto in proprio
                    e dopo un corso con <span className="active">Musa Formazione</span> per imparare i linguaggi di markup HTML e CSS e Javascript... eccomi qui con 2 certificazioni internazionali 
                    che attestano il mio percorso nel Front-End.
                </p>
                <p>
                    In poche righe spero di aver soddisfatto la vostra curiosità... <span className="active">ecco perchè è sempre difficile per me rispondere alla domanda:
                    che lavoro fai?</span>
                </p>
            </div>
        </div>
        </>
    )
}