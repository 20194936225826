const webPage = [
    {
        "id": 1,
        "title": "Uomodicasa.it",
        "link": "https://www.uomodicasa.it",
        "img": "https://www.danielechiuri.it/img/banner.png",
        "description": `Sito del mio personale brand. Raccoglie molti approfondimenti relativi al canale YouTube "l'uomo di casa" di cui sono il creatore e il proprietario.`,
        "createdby": "wordpress",
        "category": "homemade",
        "background":"https://www.uomodicasa.it/wp-content/uploads/2020/12/stregamini.jpg",
    },
    {
        "id": 2,
        "title": "Hypermind.it",
        "link": "https://www.hypermind.it",
        "img": "https://www.danielechiuri.it/img/logonero.png",
        "description": "Ingegneria, meccanica di precisione, automazione e industrializzazione di componenti. Start-up giovane e dinamica... con un sito altrettanto responsive e bilingue.",
        "createdby": "wordpress",
        "category": "industrial",
        "background":"http://hypermind.it/wp-content/uploads/2023/08/photo_2023-08-24-07.34.33.jpeg",
    },
    {
        "id": 3,
        "title": "Cuoredicioccolato.it",
        "link": "https://www.cuoredicioccolato.it",
        "img": "https://www.danielechiuri.it/img/tondorosso.png",
        "description": "Cuoredicioccolato...",
        "createdby": "wordpress",
        "category": "cooking",
        "background":"https://www.cuoredicioccolato.it/wp-content/uploads/2023/12/PIZZA_MONTANARA.jpg",
    },
    {
        "id": 4,
        "title": "FratelliLapiana.it",
        "link": "https://www.fratellilapiana.it",
        "img": "https://www.danielechiuri.it/img/fratelli lapiana.png",
        "description": "Due fratelli e una sola passione... il ferro. Serrande, cerchiature con putrelle, scale in ferro, tutto ciò che è metallo è alla loro portata. Sito Wordpress con template gratuito",
        "createdby": "wordpress",
        "category": "mechanic",
        "background":"https://www.fratellilapiana.it/wp-content/uploads/2024/02/photo_2024-02-15_06-37-45.jpg",
    },
    {
        "id": 5,
        "title": "Carryshop.it",
        "link": "https://www.carryshop.it",
        "img": "https://www.danielechiuri.it/img/carryshop_logo_nero.png",
        "description": "Avete bisogno di ristrutturare casa? Dovete scegliere piastrelle, sanitari e non sapete nemmeno da che parte cominciare? Questa è l'azienda che fa per voi Sito Wordpress costruito in collaborazione con man-free.it",
        "createdby": "wordpress",
        "category": "construction",
        "background":"https://www.carryshop.it/wp/wp-content/uploads/2023/12/sito-2.jpg",
    },
]

export default webPage