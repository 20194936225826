import './Header.css' 
import {useEffect, useState} from 'react';



const Typewriter = ({ phrases }) => {
  // in questo state indichiamo quale frase vogliamo scrivere (essendo un array di frasi, salviamo l'indice, così possiamo incrementarlo nel codice chiamando setCurrentPhraseIndex)
  const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
  // qui invece salviamo la frase da visualizzare, partendo da una stringa vuota e aggiungendo ogni carattere con setCurrentText
  const [currentText, setCurrentText] = useState('');
  // Questo ci serve per capire se stiamo scrivendo la frase, oppure se abbiamo finito e possiamo passare alla frase successiva. Useremo 2 effects: uno per scrivere la frase, l'altro per passare a quella successiva.
  const [isTyping, setIsTyping] = useState(true);

  useEffect(() => {
    // Questo effect serve per scrivere una frase, chiamando la funzione type per scrivere una lettera alla volta.
    if (isTyping) {
      // prendiamo la frase da scrivere, accedendo all'indice dell'array
      const phrase = phrases[currentPhraseIndex];
      // partiamo dalla prima lettera della frase (posizione 0)
      let currentLetterIndex = 0;
      // in questa variabile salveremo il timeout, così possiamo terminarlo a ogni ciclo, evitando memory leaks
      let letterTimeout;

      const type = () => {
        // prendiamo un pezzo della frase, partendo dalla prima lettera fino alla posizione dell'indice: in pratica trattiamo la stringa come se fosse un array di caratteri
        setCurrentText((prevText) =>
          phrase.substring(0, currentLetterIndex + 1)
        );
        // incrementiamo l'indice di 1, così possiamo prendere la lettera successiva al prossimo giro.
        currentLetterIndex++;

        // se non abbiamo ancora finito di scrivere tutte le lettere, facciamo partire un timeout che chiama di nuovo questa funzione per scrivere una nuova lettera
        if (currentLetterIndex <= phrase.length) {
          letterTimeout = setTimeout(type, 100); // Tempo di attesa tra i caratteri
        } else {
          // se abbiamo finito la frase, impostiamo il booleano a false, così triggeriamo l'effect per passare alla parola successiva
          setIsTyping(false);
        }
      };

      type();
      // useEffect() ha una funzione di return che viene eseguita quando il componente si "smonta": a quel punto possiamo togliere il timeout, visto che al prossimo re-render inizializzeremo un nuovo timeout (così evitiamo bug e memory leaks)
      return () => clearTimeout(letterTimeout);
    }
  }, [currentPhraseIndex, isTyping, phrases]);

  useEffect(() => {
    // In questo effect, se non stiamo digitando una frase, possiamo passare al prossimo elemento nell'array
    if (!isTyping) {
      const phraseTimeout = setTimeout(() => {
        // aumentiamo l'indice di 1: se abbiamo raggiunto la fine dell'array, il modulo ritornerà resto 0, quindi il contatore riparte da capo.
        // ripasso: l'operatore modulo % restituisce il resto di una divisione, quindi le operazioni saranno queste: 1%4=1, 2%4=2 3%4=3 4%4=0 ---> si ricomincia dall'inizio dell'array!
        setCurrentPhraseIndex((prevIndex) => (prevIndex + 1) % phrases.length);
        // resetto il testo
        setCurrentText('');
        // imposto la digitazione a true
        setIsTyping(true);
      }, 2000); // Tempo di attesa tra le frasi
      return () => clearTimeout(phraseTimeout);
    }
  }, [currentPhraseIndex, isTyping, phrases]);

  return <div>{currentText}</div>;
};

function Header() {
  const phrases = [
    'Web Developer',
    'Web Account Manager',
    'System Administrator',
    'Content Creator',
    'Uomo di casa',
  ];

  return (
    <div id='head'>
      <div id='phrase'> 
        <span id='whitephrase'>Che lavoro fai?</span>
        <div id='bluephrase'>
          <Typewriter phrases={phrases} />
          <span id='cursor'>|</span>
        </div>
      </div>
      <div id='divImg'>
        <img id='dan' src="https://www.danielechiuri.it/img/dan.jpg" alt='daniele'/>
      </div>
    </div>
  );
}

export default Header;