import Service from "../../data/Service";
import { Link } from "react-router-dom";


export default function Services() {
    const serviceList = Service.map(serv => {
        return (
            <article style={{
                color: "#47b5dc",
                backgroundImage: `linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.6)), url(${serv.back})`,
                backgroundSize: "cover",
                backgroundPosition: "center"
            }}>
                <h3>{serv.name}</h3>
                {serv.img}
                <h3>{serv.description}</h3>
                <button><Link to={serv.to}>Scopri di piu'</Link></button>
            </article>
        )
    })
    return (
        <>
        <section className="container">
            {serviceList}
        </section>
        </>
    )
}