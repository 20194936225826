import './App.css';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Layout from './components/Layout';
import Home from './components/Pages/Home';
import About from './components/Pages/About';
import Services from './components/Pages/Services';
import NotFound from './components/Pages/NotFound';
import WebDeveloper from './components/Pages/WebDeveloper';
import ContentCreator from './components/Pages/ContentCreator';
import SysAdmin from './components/Pages/SysAdmin';
import Wam from './components/Pages/Wam';


function App() {
  return (
    <>
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path='services' element={<Services/>}/>
            <Route path='about' element={<About/>}/>
            <Route path='webdeveloper' element={<WebDeveloper/>}/>
            <Route path='contentcreator' element={<ContentCreator/>}/>
            <Route path='sysadmin' element={<SysAdmin/>}/>
            <Route path='wam' element={<Wam/>}/>
            <Route path='*' element={<NotFound/>}/>
          </Routes>
        </Layout>
      </BrowserRouter>
    </>  
  );
}

export default App;
